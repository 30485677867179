<template>
  <div class="container-fluid mt--6 setting-marketing">
    <div class="row justify-content-md-center mb-xs-3 mb-xl-3">
      <div class="col-lg-12">
        <div class="card card-stats mb-4 mb-xl-0">
          <div class="card-header position-relative">
            <h3 class="mb-0">Marketing Operate</h3>
          </div>
          <div class="card-body p-4" v-loading="loading">
            <el-form ref="formSetting" :model="formSetting" label-width="120px">
              <fieldset>
                <legend>Fix Fee day</legend>

                <el-checkbox-group v-model="formSetting.dayOfWeek">
                  <el-checkbox
                    v-for="(day, index) in days"
                    :label="index"
                    :key="index"
                    >{{ day }}
                  </el-checkbox>
                </el-checkbox-group>
              </fieldset>
              <div class="row">
                <div class="col-lg-4">
                  <fieldset>
                    <legend>Operate time for fee</legend>
                    <el-select
                      style="width: 80px"
                      v-model="formSetting.startTime.hour"
                      placeholder="Select"
                    >
                      <el-option
                        v-for="hour in hours"
                        :key="hour"
                        :label="hour.toString().padStart(2, '0')"
                        :value="hour"
                      >
                      </el-option>
                    </el-select>
                    <span class="p-2"></span>
                    <el-select
                      style="width: 80px"
                      v-model="formSetting.startTime.minute"
                      placeholder="Hour"
                    >
                      <el-option
                        v-for="minute in minutes"
                        :key="minute"
                        :label="minute.toString().padStart(2, '0')"
                        :value="minute"
                      >
                      </el-option>
                    </el-select>
                  </fieldset>
                </div>
                <div class="col-lg-4">
                  <fieldset>
                    <legend>Close time for fee</legend>

                    <el-select
                      style="width: 80px"
                      v-model="formSetting.endTime.hour"
                      placeholder="Select"
                    >
                      <el-option
                        v-for="hour in hours"
                        :key="hour"
                        :label="hour.toString().padStart(2, '0')"
                        :value="hour"
                      >
                      </el-option>
                    </el-select>
                    <span class="p-2"></span>
                    <el-select
                      style="width: 80px"
                      v-model="formSetting.endTime.minute"
                      placeholder="Hour"
                    >
                      <el-option
                        v-for="minute in minutes"
                        :key="minute"
                        :label="minute.toString().padStart(2, '0')"
                        :value="minute"
                      >
                      </el-option>
                    </el-select>
                  </fieldset>
                </div>
              </div>

              <fieldset>
                <legend>Currency for fee</legend>

                <el-checkbox-group v-model="formSetting.currency">
                  <el-checkbox
                    v-for="(currency, index) in currencys"
                    :label="currency"
                    :key="index"
                    >{{ currency }}
                  </el-checkbox>
                </el-checkbox-group>
              </fieldset>
              <fieldset>
                <legend>Platform for fee</legend>

                <el-checkbox-group v-model="formSetting.platform">
                  <el-checkbox
                    v-for="(platform, index) in platforms"
                    :label="platform"
                    :key="index"
                    >{{ platform }}
                  </el-checkbox>
                </el-checkbox-group>
              </fieldset>
              <fieldset>
                <legend>Min Amount</legend>
                <el-input v-model="formSetting.minimumAmount"></el-input>
              </fieldset>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="onSubmit"
                  v-loading="submiting"
                  :disabled="submiting"
                  >Save</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <!----><!---->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManageApi from "@/api/manage";
export default {
  data() {
    return {
      submiting: false,
      loading: false,
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      hours: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
      ],
      minutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
      currencys: ["GBPTHB", "EURTHB", "USDTHB"],
      platforms:  ["app","browser"],
      formSetting: {
        dayOfWeek: [],
        startTime: {
          hour: 0,
          minute: 0,
        },
        endTime: {
          hour: 0,
          minute: 0,
        },
        platform: [],
        currency: [],
        minimumAmount: 100,
      },
    };
  },
  methods: {
    getSetting() {
      this.loading = true;
      ManageApi.getSettingMarketing().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.formSetting = data;
        }
      });
    },
    onSubmit() {
      this.$refs.formSetting.validate((valid) => {
        if (valid) {
          //console.log(this.formSetting);
          this.submiting = true;
          this.formSetting.minimumAmount = parseFloat(this.formSetting.minimumAmount)
          ManageApi.postSettingMarketing(this.formSetting).then(
            ({ result, message }) => {
              this.submiting = false;
              if (result) {
                this.$swal(
                  "Success",
                  "Update Marketing Fee Success",
                  "success"
                );
              } else {
                this.$swal("Message", message, "error");
              }
            }
          );
        }
      });
    },
  },
  mounted() {
    this.getSetting();
  },
};
</script>

<style lang="scss">
.setting-marketing {
  fieldset {
    border: 1px solid rgba(0, 0, 0, 0.07);
    margin: 16px 2px;
    padding: 1.2rem;
  }
  legend {
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding: 3px 10px;
    font-size: 12px;
    font-weight: bold;
    width: auto;
  }
}
</style>
